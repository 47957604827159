import { useRequestHeader, useRuntimeConfig } from '#imports';

export const useOrganizationDomain = (): null | string => {
  const config = useRuntimeConfig();

  const enforcedDomain = config.public.localEnforcedOrganizationDomain;

  if (enforcedDomain) {
    return enforcedDomain === 'NONE' ? null : enforcedDomain;
  }

  const host = useRequestHeader('host') ?? globalThis.location.host;

  return host === config.public.appDomain ? null : host;
};
